import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FaleComAGente from "../components/fale-com-a-gente"
import MenuSolucoes from "../components/menu-solucoes"

import scrollTo from "gatsby-plugin-smoothscroll"

//Icons
import arrowDownIcon from "../images/icons/arrow-down.svg"

//Imagens
import transmissoes_ao_vivo from "../images/solucoes/transmissoes_ao_vivo.svg"
import web_videos from "../images/solucoes/web_videos.svg"
import videos_institucionais from "../images/solucoes/videos_institucionais.svg"
import gravacao_aulas from "../images/solucoes/gravacao_aulas.svg"
import comerciais_tv from "../images/solucoes/comerciais_tv.svg"
import tv_corporativa from "../images/solucoes/tv_corporativa.svg"
import conferencias_internas from "../images/solucoes/conferencias_internas.svg"
import cobertura_jornalistica from "../images/solucoes/cobertura_jornalistica.svg"
import projetos_especiais from "../images/solucoes/projetos_especiais.svg"
import podcast from "../images/solucoes/podcast.svg"

const SolutionsPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Soluções" />
    <Container>
      <ContentWrapper>
        <SectionOne id="section-one">
          <div className="title">
            <div>
              <h1>soluções</h1>
              <h2>conheça algumas possibilidades</h2>
            </div>
            <div className="arrow-down">
              <button
                type="button"
                onClick={() => {
                  scrollTo("#section-two")
                }}
              >
                <img src={arrowDownIcon} alt="Scroll para baixo" />
              </button>
            </div>
          </div>
          <MenuSolucoes />
        </SectionOne>
      </ContentWrapper>
      <ContentWrapper style={{ background: "#ffffff" }}>
        <SectionTwo id="section-two">
          <div className="text">
            <h3>Transmissões ao vivo</h3>
            <p>
              Alcance seu público de forma assertiva com tudo o que as
              plataformas digitais têm a oferecer. Somos especializados em
              transmissão ao vivo em todos os segmentos de eventos.
            </p>
          </div>
          <div className="image">
            <img src={transmissoes_ao_vivo} alt="Transmissões ao vivo" />
          </div>
        </SectionTwo>
      </ContentWrapper>
      <ContentWrapper>
        <SectionThree id="section-three">
          <div className="text">
            <h3>Web vídeos</h3>
            <p>
              A internet virou a fonte mais dinâmica e fácil para assistir a
              vídeos. Dessa forma, fazemos a produção completa de vídeos para a
              web com roteiro, duração, maneiras de divulgar o vídeo e que tipo
              de resultados devem ser esperados.
            </p>
          </div>
          <div className="image">
            <img src={web_videos} alt="Web vídeos" />
          </div>
        </SectionThree>
      </ContentWrapper>
      <ContentWrapper style={{ background: "#ffffff" }}>
        <SectionFour id="section-four">
          <div className="text">
            <h3>Vídeos institucionais</h3>
            <p>
              O sucesso de uma marca ou produto está diretamente ligado à sua
              divulgação. Somos especialistas na produção de vídeos
              institucionais, utilizando um formato de acordo com o estilo de
              sua empresa.
            </p>
          </div>
          <div className="image">
            <img src={videos_institucionais} alt="Vídeos institucionais" />
          </div>
        </SectionFour>
      </ContentWrapper>
      <ContentWrapper>
        <SectionFive id="section-five">
          <div className="text">
            <h3>Gravação de aulas e cursos</h3>
            <p>
              Gravamos sua aula num formato rápido e acessível. Com a gravação,
              seu público alvo é atingido e, seu negócio, rentabilizado. Seu
              material consegue ter um maior alcance, com alunos em diferentes
              lugares do mundo.
            </p>
          </div>
          <div className="image">
            <img src={gravacao_aulas} alt="Gravação de aulas e cursos" />
          </div>
        </SectionFive>
      </ContentWrapper>
      <ContentWrapper style={{ background: "#ffffff" }}>
        <SectionSix id="section-six">
          <div className="text">
            <h3>Comerciais de TV</h3>
            <p>
              Trabalhamos com a produção de comerciais voltados para a
              televisão, unindo o que há de novo no mercado da tecnologia, com
              um dos meios de comunicação mais consolidados do país.
            </p>
          </div>
          <div className="image">
            <img src={comerciais_tv} alt="Comerciais de TV" />
          </div>
        </SectionSix>
      </ContentWrapper>
      <ContentWrapper>
        <SectionSeven id="section-seven">
          <div className="text">
            <h3>TV corporativa</h3>
            <p>
              Desenvolvemos um circuito de comunicação interna dentro de sua
              empresa com a tv corporativa, fortalecendo o contato direto entre
              clientes e colaboradores.
            </p>
          </div>
          <div className="image">
            <img src={tv_corporativa} alt="TV corporativa" />
          </div>
        </SectionSeven>
      </ContentWrapper>
      <ContentWrapper style={{ background: "#ffffff" }}>
        <SectionEight id="section-eight">
          <div className="text">
            <h3>Conferências internas</h3>
            <p>
              Atuamos em conferências internas, desenvolvendo uma experiência de
              sucesso de forma absolutamente profissional, cumprindo a proposta
              de trazer resultados positivos ao final do evento.
            </p>
          </div>
          <div className="image">
            <img src={conferencias_internas} alt="Conferências internas" />
          </div>
        </SectionEight>
      </ContentWrapper>
      <ContentWrapper>
        <SectionNine id="section-nine">
          <div className="text">
            <h3>Cobertura jornalística</h3>
            <p>
              Oferecemos cobertura jornalística completa ao seu evento, com
              profissionais preparados para as demandas necessárias, como áudio,
              imagens e texto.
            </p>
          </div>
          <div className="image">
            <img src={cobertura_jornalistica} alt="Cobertura jornalística" />
          </div>
        </SectionNine>
      </ContentWrapper>
      <ContentWrapper style={{ background: "#ffffff" }}>
        <SectionTen id="section-ten">
          <div className="text">
            <h3>Projetos especiais</h3>
            <p>
              Se o seu objetivo for desenvolver projetos audiovisuais especiais,
              a Zoombe pode te ajudar. Oferecemos o melhor da tecnologia para a
              produção e finalização de ideias.
            </p>
          </div>
          <div className="image">
            <img src={projetos_especiais} alt="Projetos especiais" />
          </div>
        </SectionTen>
      </ContentWrapper>
      <ContentWrapper>
        <SectionEleven id="section-eleven">
          <div className="text">
            <h3>Podcast</h3>
            <p>
              Facilitamos o acesso aos seus conteúdos com a produção de
              podcasts. Trazemos alta qualidade em áudio, pronto para ser
              inserido na plataforma de sua preferência.
            </p>
          </div>
          <div className="image">
            <img src={podcast} alt="Podcast" />
          </div>
        </SectionEleven>
      </ContentWrapper>

      <FaleComAGente darkmode />
    </Container>
  </Layout>
)

const Container = styled.div`
  width: 100%;
  background: #f4f4f4;
`

const ContentWrapper = styled.div`
  width: 100%;
  background-color: #f8f8f8;
`

const SectionOne = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  height: calc(100vh - 100px);

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding-top: 100px;

  button {
    background: none;
    border: none;
    color: black;
    &:hover {
      color: red;
      cursor: pointer;
    }
  }

  div.title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;

    h1 {
      color: red;
      font-size: 40px;
      margin-bottom: 0px;
    }
    h2 {
      font-size: 50px;
      color: #000000;
      font-weight: lighter;
      max-width: 491px;
    }

    div.arrow-down {
      img {
        cursor: pointer;
        width: 40px;
      }
    }
  }
`
const SectionTwo = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;

  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionThree = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;

  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionFour = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionFive = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;

  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionSix = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;

  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionSeven = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;
  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionEight = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 20px;

  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionNine = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;
  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionTen = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;

  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
const SectionEleven = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;
  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.text {
    h3 {
      color: #f44336;
      font-size: 53px;
    }
    p {
      max-width: 500px;
    }
  }
  div.image {
    padding-top: 50px;
    img {
      width: 100%;
      max-width: 600px;
    }
  }

  @media (max-width: 1000px) {
    min-height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;

    div.text {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    div.image {
      padding-top: 30px;
    }
  }
`
export default SolutionsPage
