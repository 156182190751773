import React from "react"

import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

export default function MenuSolucoes() {
  return (
    <MenuContainer className="menu">
      <ul>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-two")
            }}
          >
            Transmissões ao vivo
          </button>
        </li>

        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-three")
            }}
          >
            Web vídeos
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-four")
            }}
          >
            Vídeos institucionais
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-five")
            }}
          >
            Gravação de aulas e cursos
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-six")
            }}
          >
            Comerciais de TV
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-seven")
            }}
          >
            TV corporativa
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              scrollTo("#section-eight")
            }}
          >
            {" "}
            Conferências internas
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-nine")
            }}
          >
            {" "}
            Cobertura jornalística
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-ten")
            }}
          >
            {" "}
            Projetos especiais
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              scrollTo("#section-eleven")
            }}
          >
            Podcast
          </button>
        </li>
      </ul>
    </MenuContainer>
  )
}

const MenuContainer = styled.div`
  button {
    background: none;
    border: none;
    color: black;
    &:hover {
      color: red;
      cursor: pointer;
    }
  }
  ul {
    list-style-type: none;
    color: #000000;

    li {
      cursor: pointer;
      &:hover {
        color: #ff0000;
      }
    }
  }

  @media (max-width: 781px) {
    display: none;
  }
`
